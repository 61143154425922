import * as React from "react"

import PageLayout from "../components/page-layout"
import { Form, Button } from "react-bootstrap"
import Message from "../components/message"

const Inquiry = () => (
    <PageLayout title="入力フォーム">
        <div className="inquiry-form">
            <Message>
                {`お問合せありがとうございます。
            体験レッスンをご希望の方は、入力フォームに必要事項を入力して送信して下さい。
            2日以内にこちらからご連絡いたします。
            （体験1レッスンにつき2,000円、ただしご入会の場合はお手続き時にご返金）`}
            </Message>

            <div class="container py-4">
                <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="hidden" name="bot-field" />
                    <Form.Group controlId="frmName">
                        <Form.Label>お名前</Form.Label><span class="badge badge-danger">必須</span>
                        <Form.Control placeholder="お名前" name="name" required></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="frmEmail">
                        <Form.Label>メールアドレス</Form.Label><span class="badge badge-danger">必須</span>
                        <Form.Control type="email" name="email" placeholder="メールアドレス" required></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="frmClass">
                        <Form.Label>希望クラス</Form.Label>
                        <Form.Control as="select" name="class">
                            <option value="選択なし">選択してください</option>
                            <option value="子どもクラス">子どもクラス</option>
                            <option value="大人クラス">大人クラス</option>
                            <option value="宝塚受験クラス">宝塚受験クラス</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="frmTextArea">
                        <Form.Label>お問合せ内容</Form.Label>
                        <Form.Control as="textarea" rows={3} name="content" placeholder="年齢と学年（大人クラスは不要）、体験レッスンの希望日時、バレエ経験などをご入力ください。" />
                    </Form.Group>
                    <Form.Group>
                        <Button variant="secondary" type="submit">
                            送信
                        </Button>
                    </Form.Group>
                </form>
            </div>
        </div>
    </PageLayout >
)

export default Inquiry